export const AUTH_TOKEN = "xclusv_auth_access_token";
export const AUTH_USER_TOKEN = "xclusv_auth_user_token";

export const MEMBER_OPTIONS = [
  "All Members",
  "Admins",
  "Member Requests",
  "Pending Invitations",
];

export const PLAYSTORE_URL =
  "https://play.google.com/store/apps/details?id=app.xclusv.xclusv";

export const APPLESTORE_URL =
  "https://apps.apple.com/us/app/xclusv/id6448131761";

export const MBO_MEMBER_REQUEST_STATUS = {
  PENDING: "0",
  APPROVED: "1",
  DENIED: "2",
};

export const allowedTypes = ["csv", "text/csv"];

export const columns = [
  {
    name: "Email",
    selector: "email",
    sortable: true,
  },
  {
    name: "First Name",
    selector: "first_name",
    sortable: true,
  },
  {
    name: "Last Name",
    selector: "last_name",
    sortable: true,
    right: true,
  },
  {
    name: "Group 1",
    selector: "group_1",
    sortable: true,
    right: true,
  },
  {
    name: "Group 2",
    selector: "group_2",
    sortable: true,
    right: true,
  },
  {
    name: "Group 3",
    selector: "group_3",
    sortable: true,
    right: true,
  },
];
