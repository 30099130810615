import { InMemoryCache, createHttpLink } from "@apollo/client";
import { onError } from "apollo-link-error";
import { ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AUTH_TOKEN } from "constants";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_XCLUSV_API_URL
    : "http://localhost:4000/graphql";

const httpLink = createHttpLink({
  uri: baseUrl,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: "no-cors",
  },
});

export default client;
