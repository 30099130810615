import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button, Stack, Typography, Box } from "@mui/material";

import Logo from "../../assets/xclusv_logo.png";
import { PLAYSTORE_URL, APPLESTORE_URL } from "constants";

const ActivationSuccessPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state && !state.email && !state.hashNum) {
      navigate("/signup");
    }
  }, []);

  const handleAndroidUrl = () => {
    window.open(PLAYSTORE_URL, "_blank");
  };

  const handleAppleUrl = () => {
    window.open(APPLESTORE_URL, "_blank");
  };

  return (
    <div style={AuthContainer}>
      <Box
        marginLeft="auto"
        marginRight="auto"
        width={500}
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          width={250}
          height={80}
          alt="Your logo."
          src={Logo}
        />
        <Typography
          variant="h2"
          sx={{ color: "#FFF", fontSize: "25px", textAlign: "center" }}
          padding={3}
        >
          Your Account has been confirmed.
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            color: "#FFF",
          }}
        >
          Click a button to download the app.
        </Typography>

        <Button
          sx={{
            fontSize: "15px",
            fontWeight: "bold",
            m: "2rem 0",
            p: "1rem",
            backgroundColor: "#BCBCBC",
            color: "#020202",
            "&:hover": { color: "#FFF" },
            borderRadius: "2.5rem",
            width: { xs: 200, sm: 300, md: 400 },
            height: "6vh",
          }}
          onClick={handleAndroidUrl}
        >
          Android
        </Button>

        <Button
          sx={{
            fontSize: "15px",
            fontWeight: "bold",
            p: "1rem",
            backgroundColor: "#CB9E00",
            color: "#020202",
            "&:hover": { color: "#FFF" },
            borderRadius: "2.5rem",
            width: { xs: 200, sm: 300, md: 400 },
            height: "6vh",
          }}
          onClick={handleAppleUrl}
        >
          Apple
        </Button>
      </Box>
    </div>
  );
};

const AuthContainer = {
  backgroundColor: "#07162b",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const LogoContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default ActivationSuccessPage;
