import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik/dist";
import * as yup from "yup";
import {
  Container,
  Stack,
  Typography,
  TextField,
  Button,
  Link,
  Alert,
} from "@mui/material";

import { VERIFY_CODE } from "graphql/mutations/authMutations";

const verificationCodeSchema = yup.object().shape({
  verificationCode: yup.string().required("required"),
});

const initialValuesVerifyEmail = {
  verificationCode: "",
};

const VerifyEmailForm = ({ email }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [sucessMessage, setSuccessMessage] = useState(null);
  const [verifyCode] = useMutation(VERIFY_CODE);

  const handleFormSubmit = async (values, onSubmitProps) => {
    const { verificationCode } = values;

    try {
      const { data } = await verifyCode({
        variables: { code: verificationCode },
      });
      const { success, message } = data.verifyEmail;
      if (success) {
        setSuccessMessage(message + " Please login to your new account.");
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesVerifyEmail}
      validationSchema={verificationCodeSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Container spacing={2} maxWidth="sm">
            <Stack spacing={2} paddingBottom={1} width="30vw">
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#FFF",
                  fontSize: "15px",
                }}
              >
                An email with the confirmation code has been sent to: {email}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#FFF",
                  fontSize: "15px",
                }}
              >
                If this is not your correct email, contact us at
                info@xclusv.app.
              </Typography>

              {sucessMessage && (
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FFF",
                    fontSize: "15px",
                  }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                  <Link href="/" underline="always">
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        textAlign: "center",
                        color: "#FFF",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      Click here to login to your account.
                    </Typography>
                  </Link>
                </Typography>
              )}
              {!sucessMessage && (
                <>
                  <TextField
                    placeholder="Verification Code"
                    name="verificationCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.verificationCode}
                    error={
                      Boolean(touched.verificationCode) &&
                      Boolean(errors.verificationCode)
                    }
                    helperText={
                      touched.verificationCode && errors.verificationCode
                    }
                    required
                  />

                  <Button
                    onClick={handleSubmit}
                    fullWidth
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      m: "2rem 0",
                      p: "1rem",
                      backgroundColor: "#CB9E00",
                      color: "#020202",
                      "&:hover": { color: "#FFF" },
                      borderRadius: "2.5rem",
                    }}
                  >
                    Verify Code
                  </Button>
                </>
              )}
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            </Stack>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default VerifyEmailForm;
