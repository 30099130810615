import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";

import { AUTH_TOKEN } from "constants";
import OrganizationList from "components/OrganizationList";
import { GET_MY_MBOS } from "graphql/queries/organizationQueries";
import Loader from "components/Loader";
import { CREATE_MBO } from "graphql/mutations/organizationMutations";

const OrganizationListPage = () => {
  const { loading, error, data } = useQuery(GET_MY_MBOS);
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");

  const [createMBO] = useMutation(CREATE_MBO, {
    refetchQueries: [{ query: GET_MY_MBOS }],
  });

  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      navigate("/app/organizations");
    }

    if (!token) {
      navigate("/");
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // calls the graphql mutation to create an mbo
    const { data, error } = await createMBO({
      variables: { name, email, phone, address, website, description },
    });

    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setWebsite("");
    setDescription("");
    handleClose();
  };

  if (loading) return <Loader />;

  if (error) {
    setErrorMessage("Oops An Error Occurred.. ");
  }

  return (
    <>
      <Stack direction="row" spacing={40}>
        <h1 style={HeaderOne}>Organizations</h1>
        <NavLink to="#" style={LinkStyle} onClick={handleClickOpen}>
          create an organization
        </NavLink>
      </Stack>
      <div style={{ marginLeft: "5vw" }}>
        {data.adminMBOs && data.adminMBOs?.length > 0 ? (
          <OrganizationList organizations={data.adminMBOs} />
        ) : (
          <p style={Message}>
            You currently are not in an organization. Create an organization in
            order to move forward
          </p>
        )}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogContent sx={{ padding: 5 }}>
            <Grid container>
              <form>
                <Grid item xs={20}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={20}>
                      <Typography variant="h3">
                        Create An Organization
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={7}>
                      <TextField
                        type="text"
                        variant="outlined"
                        color="secondary"
                        label="Name of Organization"
                        required
                        size="small"
                        className="inputRounded"
                        sx={{
                          width: 355,
                          background: "#ffffff",
                          border: "1px solid #d9d9d9",
                          borderRadius: "12px",
                          marginTop: "10px",
                        }}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />

                      <TextField
                        type="text"
                        variant="outlined"
                        color="secondary"
                        label="Corporate Email of Organization"
                        size="small"
                        className="inputRounded"
                        sx={{
                          width: 355,
                          background: "#ffffff",
                          border: "1px solid #d9d9d9",
                          borderRadius: "12px",
                          marginTop: "15px",
                        }}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />

                      <TextField
                        type="text"
                        variant="outlined"
                        color="secondary"
                        label="Phone Number of Organization"
                        size="small"
                        className="inputRounded"
                        sx={{
                          width: 355,
                          background: "#ffffff",
                          border: "1px solid #d9d9d9",
                          borderRadius: "12px",
                          marginTop: "15px",
                        }}
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                      />

                      <TextField
                        type="text"
                        variant="outlined"
                        color="secondary"
                        label="Address"
                        size="small"
                        className="inputRounded"
                        sx={{
                          width: 355,
                          background: "#ffffff",
                          border: "1px solid #d9d9d9",
                          borderRadius: "12px",
                          marginTop: "15px",
                        }}
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                      />

                      <TextField
                        type="text"
                        variant="outlined"
                        color="secondary"
                        label="Website"
                        size="small"
                        className="inputRounded"
                        sx={{
                          width: 355,
                          background: "#ffffff",
                          border: "1px solid #d9d9d9",
                          borderRadius: "12px",
                          marginTop: "15px",
                        }}
                        value={website}
                        onChange={(event) => setWebsite(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12} align="right" style={{ padding: 0 }}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          margin="dense"
                          multiline
                          required
                          rows="5"
                          variant="outlined"
                          label="Description"
                          id="mbo-description"
                          sx={{
                            width: 355,
                            background: "#ffffff",
                            border: "1px solid #d9d9d9",
                            borderRadius: "12px",
                            marginTop: "15px",
                          }}
                          value={description}
                          onChange={(event) =>
                            setDescription(event.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button onClick={handleClose} size="large" sx={ButtonStyle}>
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      size="large"
                      sx={ButtonStyle}
                    >
                      Create
                    </Button>
                  </DialogActions>
                </Grid>
              </form>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

const Container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "@media (minWidth:576px)": {
    flexDirection: "row",
  },
};

const HeaderOne = {
  marginLeft: "5vw",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "55px",
};

const LinkStyle = {
  marginTop: "10vh",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "13px",
  lineHeight: "16px",
  letterSpacing: "0.08em",
  textDecorationLine: "underline",
  textTransform: "uppercase",
  color: "#020202",
};

const Message = {
  marginLeft: "6vw",
  marginTop: "20vh",
  width: "900px",
  height: "43px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "19px",
};

const ButtonStyle = {
  color: "#ffffff",
  backgroundColor: "#CB9E00",
  margin: 1,
  padding: 1,
  borderRadius: 10,
};

export default OrganizationListPage;
