import { useState, useEffect } from "react";
import { AUTH_TOKEN } from "constants";

import { firebaseApp, auth } from "firebase.config";

const UserIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  const getToken = async function (firebaseApp) {
    const token = (await firebaseApp.user)
      ? await firebaseApp.user.getIdToken(true)
      : undefined;
    return token;
  };

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await getToken(user);

        if (token) {
          localStorage.setItem(AUTH_TOKEN);
        }
      } else {
        localStorage.removeItem(AUTH_TOKEN);
      }
      setIsAuthenticated(user ? true : false);
    });
  }, []);

  return isAuthenticated;
};

export default UserIsAuthenticated;
