import { useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { INVITE_TO_MBO } from "graphql/mutations/organizationMutations";
import { isEmail } from "utils";

const AddMemberModal = ({
  open,
  handleInviteModal,
  handleCloseInviteModal,
  mainMbo,
}) => {
  const [email, setEmail] = useState("");
  const [inviteToMbo] = useMutation(INVITE_TO_MBO);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // validate the email field
    if (!isEmail(email)) {
      toast.info("Email is not valid");
    } else {
      // calls the graphql mutation to create an mbo
      const { data, error } = await inviteToMbo({
        variables: { email, mbo_id: mainMbo },
      });

      if (data.inviteToMBOByEmail.id) {
        toast.success("Successfully invited User");
      }

      if (error) {
        toast.error("An error occurred, please try again later");
      }

      setEmail("");
    }
  };

  const handleCloseModal = async (event) => {
    // reset the value of the email
    setEmail("");
    handleCloseInviteModal();
  };

  return (
    <>
      <Modal
        aria-labelledby="invite-users-modal-title"
        aria-describedby="invite-users-modal-description"
        open={open}
        onClose={handleInviteModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={ModalStyle}>
            <Typography
              id="invite-users-modal-title"
              variant="h1"
              component="h1"
              sx={{ fontWeight: "700", fontSize: "55px" }}
            >
              Invite A User
            </Typography>
            <TextField
              id="invite-single-user"
              label="Email of User To Invite"
              variant="outlined"
              sx={{
                marginLeft: "0.25em",
                marginTop: "1em",
                height: "5em",
                width: "30em",
              }}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />

            <div style={ButtonDiv}>
              <Button sx={InviteUsers} onClick={handleSubmit}>
                Invite User
              </Button>
              <Button sx={CancelButton} onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer />
    </>
  );
};

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "40vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  paddingLeft: 4,
  paddingTop: 4,
  paddingRight: 4,
  paddingBottom: 4,
};
const ButtonDiv = {
  display: "flex",
  flexDirection: "row",
};
const CancelButton = {
  color: "#000000",
  background: "#EEEEEE",
  width: "20em",
  margin: 1,
  padding: 1,
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

const InviteUsers = {
  color: "#000000",
  background: "#CB9E00",
  width: "20em",
  margin: 1,
  padding: 1,
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

export default AddMemberModal;
