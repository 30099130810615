import React from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  View,
  Text,
} from "@react-pdf/renderer";
import logo from "../../assets/blue_logo.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 200,
    height: 100,
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 18,
    marginLeft: "auto",
    marginRight: "auto",
  },
  reportTitle: {
    color: "#f44336",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },
  mboNameContainer: {
    flexDirection: "row",
    marginTop: 15,
    justifyContent: "flex-end",
  },
  reportDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  reportDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    width: 70,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#002147",
    backgroundColor: "#002147",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    color: "#ffffff",
  },
  description: {
    width: "80%",
  },
  row: {
    width: "20%",
    borderRightColor: "#ffffff",
    borderRightWidth: 1,
  },
  dataRow: {
    flexDirection: "row",
    borderBottomColor: "#002147",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  colDescription: {
    width: "80%",
    textAlign: "left",
    paddingLeft: 1,
  },
  colRow: {
    width: "20%",
    textAlign: "center",
    borderRightColor: "#000000",
    borderRightWidth: 1,
  },
});

const ReportTitle = ({ title, mboName }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{title}</Text>
  </View>
);

const MboNumber = ({ mboName, currDate }) => (
  <>
    <View style={styles.mboNameContainer}>
      <Text style={styles.label}>Organisation:</Text>
      <Text style={styles.reportDate}>{mboName}</Text>
    </View>
    <View style={styles.reportDateContainer}>
      <Text style={styles.label}>Date: </Text>
      <Text>{currDate}</Text>
    </View>
  </>
);

const ErrorTableRow = ({ errors }) => {
  const rows = errors.map((item) => (
    <View style={styles.dataRow} key={item.row}>
      <Text style={styles.colRow}>{item.row}</Text>
      <Text style={styles.colDescription}>{item.description}</Text>
    </View>
  ));
  return <>{rows}</>;
};

const ErrorTableHeader = () => (
  <View style={styles.tableContainer}>
    <View style={styles.container}>
      <Text style={styles.row}>Row</Text>
      <Text style={styles.description}>Error Description</Text>
    </View>
  </View>
);

const ErrorItemsTable = ({ errors }) => (
  <>
    <ErrorTableHeader />
    <ErrorTableRow errors={errors} />
  </>
);

const PdfReport = ({ errors, currentDate, mboName }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={logo} />
      <ReportTitle title="Error Report" />
      <MboNumber mboName={mboName} currDate={currentDate} />
      <ErrorItemsTable errors={errors} />
    </Page>
  </Document>
);

export default PdfReport;
