import { useContext, useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

import {
  TextField,
  Button,
  Container,
  Stack,
  Alert,
  Typography,
  Link,
  Box,
} from "@mui/material";

import { EmailOutlined } from "@mui/icons-material";

import VerifyEmailForm from "./VerifyEmailForm";

const ConfirmAccountPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state && !state.email) {
      navigate("/signup");
    }
  }, []);

  return (
    <div style={AuthContainer}>
      <VerifyEmailForm email={state.email} />
    </div>
  );
};

const AuthContainer = {
  backgroundColor: "#07162b",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default ConfirmAccountPage;
