import { useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_MBO_GROUPS } from "graphql/queries/organizationQueries";
import { CREATE_MBO_GROUP } from "graphql/mutations/membersMutations";
import Loader from "components/Loader";

const AddGroupModal = ({
  open,
  handleOpenCreate,
  handleCloseCreate,
  mainMbo,
  members,
}) => {
  const [groupName, setGroupName] = useState("");
  const [createMboGroup] = useMutation(CREATE_MBO_GROUP, {
    refetchQueries: [{ query: GET_MBO_GROUPS, variables: { mbo_id: mainMbo } }],
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (groupName === "") {
      toast.info("Group Name is required");
    } else {
      // calls the graphql mutation to create a group
      const { data, error } = await createMboGroup({
        variables: { mbo_id: mainMbo, name: groupName },
      });

      if (data.createMBOGroup.name) {
        toast.success("Successfully created group");
      }

      if (error) {
        toast.error("An error occurred, please try again later");
      }
      // reset the values
      setGroupName("");
    }
  };

  const handleCloseModal = async (event) => {
    // reset the values
    setGroupName("");
    handleCloseCreate();
  };

  return (
    <>
      <Modal
        aria-labelledby="create-group-modal-title"
        aria-describedby="create-group-modal-description"
        open={open}
        onClose={handleOpenCreate}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={ModalStyle}>
            <Typography
              id="create-group-modal-title"
              variant="h1"
              component="h1"
              sx={{ fontWeight: "700", fontSize: "55px" }}
            >
              Create Group
            </Typography>
            <Typography
              id="create-group-modal-description"
              sx={{
                mt: 2,
                fontWeight: "400",
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Adds a group to the selected Organisation on the sidebar
            </Typography>
            <TextField
              id="create-single-group"
              label="Enter name of group"
              variant="outlined"
              sx={{
                marginLeft: "0.25em",
                marginTop: "1em",
                height: "5em",
                width: "30em",
              }}
              value={groupName}
              onChange={(event) => setGroupName(event.target.value)}
            />

            <div style={ButtonDiv}>
              <Button sx={InviteUsers} onClick={handleSubmit}>
                Create Group
              </Button>
              <Button sx={CancelButton} onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer />
    </>
  );
};

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "50vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ButtonDiv = {
  marginTop: "0vh",
  display: "flex",
  flexDirection: "row",
};
const CancelButton = {
  color: "#000000",
  background: "#EEEEEE",
  width: "20em",
  margin: 1,
  padding: 1,
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

const InviteUsers = {
  color: "#000000",
  background: "#CB9E00",
  width: "20em",
  margin: 1,
  padding: 1,
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

export default AddGroupModal;
