import { gql } from "@apollo/client";

const CREATE_MBO = gql`
  # prettier-ignore
  mutation createMBO(
    $name: String!,
    $email: String,
    $phone: String,
    $address: String,
    $website: String,
    $description: String!
  ) {
    createMBO(
      MBOData: {
        name: $name,
        email: $email,
        phone_number: $phone,
        address: $address,
        website: $website,
        description: $description
      }
    ) {
      name
    }
  }
`;

const UPDATE_MBO = gql`
  # prettier-ignore
  mutation updateMBO(
    $id: String!,
    $name: String!,
    $email: String,
    $phone: String,
    $address: String,
    $website: String,
    $description: String!
    ) {
    updateMBO(MBOData: { 
        id: $id, 
        name: $name,
        email: $email,
        phone_number: $phone,
        address: $address,
        website: $website,
        description: $description }) {
      id,
      name,
      description
    }
  }
`;

const DELETE_MBO = gql`
  mutation deleteMBO($id: String!) {
    deleteMBO(id: $id) {
      success
      message
    }
  }
`;

const INVITE_TO_MBO = gql`
  # prettier-ignore
  mutation inviteToMBOByEmail($email: String!, $mbo_id: String!) {
  inviteToMBOByEmail(MBOInvitationByEmailData: { email: $email, mbo_id: $mbo_id }) {
    id, mbo_id, user_id
  }
}
`;

export { CREATE_MBO, UPDATE_MBO, DELETE_MBO, INVITE_TO_MBO };
