import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik/dist";
import {
  Container,
  Stack,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  Alert,
} from "@mui/material";
import * as yup from "yup";

import Logo from "../../assets/xclusv_logo.png";
import { LOGIN_USER } from "graphql/mutations/authMutations";
import { auth, signInWithEmailAndPassword } from "../../firebase.config";
import { AUTH_TOKEN } from "constants";

const loginSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValuesLogin = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [login] = useMutation(LOGIN_USER);
  const navigate = useNavigate();

  const handleFormSubmit = async (values, onSubmitProps) => {
    const { email, password } = values;

    try {
      const firebaseAuth = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const { accessToken } = firebaseAuth.user;

      if (accessToken) {
        localStorage.setItem(AUTH_TOKEN, accessToken);
        navigate("/app/dashboard");
      }
    } catch (error) {
      setErrorMessage("Email/Password is not correct");
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Container spacing={2} maxWidth="sm">
            <Stack spacing={3} paddingTop={5} width="30vw">
              <div style={LogoContainer}>
                <Box
                  component="img"
                  width={250}
                  height={80}
                  alt="Your logo."
                  src={Logo}
                />
              </div>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              <TextField
                placeholder="Email"
                name="email"
                type={"email"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                error={Boolean(touched.email) && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                required
              />
              <TextField
                placeholder="Enter A Password"
                name="password"
                type={"password"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                required
              />
              <Button
                onClick={handleSubmit}
                fullWidth
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: "#CB9E00",
                  color: "#020202",
                  "&:hover": { color: "#FFF" },
                  borderRadius: "2.5rem",
                }}
              >
                Login
              </Button>
              <Link href="/signup" underline="always">
                <Typography
                  sx={{
                    textDecoration: "underline",
                    textAlign: "center",
                    color: "#FFF",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Don't have an account? Join XCLUSV.
                </Typography>
              </Link>
            </Stack>
          </Container>
        </form>
      )}
    </Formik>
  );
};

const LogoContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default LoginForm;
