import { gql } from "@apollo/client";

const GET_MY_MBOS = gql`
  query adminMBOs {
    adminMBOs {
      id
      name
      description
      email
      website
      phone_number
      address
      owner {
        id
        email
      }
    }
  }
`;

const GET_MBO = gql`
  query getMbo($id: String!) {
    mbo(id: $id) {
      id
      name
      description
      email
      website
      phone_number
      address
      owner {
        id
      }
      members {
        id
        user_id
      }
      admins {
        id
        user_id
      }
      profile_picture_url
      permissions {
        read
        create
        update
        delete
        readMembers
      }
    }
  }
`;

const GET_MBO_MEMBERS = gql`
  query mboMembers($mbo_id: String!) {
    mboMembers(mbo_id: $mbo_id) {
      id
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
`;

const GET_MBO_GROUPS = gql`
  query mboGroups($mbo_id: String!) {
    mboGroups(mbo_id: $mbo_id) {
      id
      name
      member_count
    }
  }
`;

const GET_MBO_ADMINS = gql`
  query mboAdmins($mbo_id: String!) {
    mboAdmins(mbo_id: $mbo_id) {
      id
      mbo_id
      user_id
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

const GET_MBO_MEMBER_REQUESTS = gql`
  query mboMemberRequests($mbo_id: String!) {
    mboMemberRequests(mbo_id: $mbo_id, status_id: 0) {
      id
      status_id
      mbo {
        id
        name
      }
      user {
        id
        email
      }
      permissions {
        read
      }
    }
  }
`;

const GET_MBO_MEMBER_INVITATIONS = gql`
  query mboMemberInvitations($mbo_id: String!) {
    mboMemberInvitations(mbo_id: $mbo_id, status_id: 0) {
      id
      status_id
      mbo {
        id
        name
      }
      user {
        id
        email
      }
      permissions {
        read
      }
    }
  }
`;

export {
  GET_MY_MBOS,
  GET_MBO,
  GET_MBO_MEMBERS,
  GET_MBO_GROUPS,
  GET_MBO_ADMINS,
  GET_MBO_MEMBER_REQUESTS,
  GET_MBO_MEMBER_INVITATIONS,
};
