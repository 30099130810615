import { gql } from "@apollo/client";

const SIGNUP_USER = gql`
  mutation createUser($email: String!, $password: String!) {
    createUser(userData: { email: $email, password: $password }) {
      id
      email
      first_name
      last_name
      salt
    }
  }
`;

const SEND_EMAIL_VERIFICATION = gql`
  mutation sendEmailVerification {
    sendEmailVerification {
      success
    }
  }
`;

const VERIFY_CODE = gql`
  mutation verifyEmail($code: String!) {
    verifyEmail(verificationData: { code: $code }) {
      success
      message
    }
  }
`;

const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(loginUserInput: { email: $email, password: $password }) {
      id
      name
      description
    }
  }
`;

const ACTIVATE_ACCOUNT = gql`
  # prettier-ignore
  mutation completeUserAccount(
    $email: String!,
    $hash: String!,
    $password: String!,
    $first_name: String!,
    $last_name: String!
  ) {
    completeUserAccount(
      completeUserData: {
        email: $email,
        hash: $hash,
        password: $password,
        first_name: $first_name,
        last_name: $last_name
      }
    ) {
      success
      message
    }
  }
`;

export {
  SIGNUP_USER,
  LOGIN_USER,
  SEND_EMAIL_VERIFICATION,
  VERIFY_CODE,
  ACTIVATE_ACCOUNT,
};
