import OrganizationItem from "components/OrganizationItem";

const OrganizationList = ({ organizations, edit, handleEditOpen }) => {
  return (
    <div style={Container}>
      {organizations.map((organization) => (
        <OrganizationItem
          key={organization.id}
          organization={organization}
          handleEditOpen={handleEditOpen}
        />
      ))}
    </div>
  );
};

const Container = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

export default OrganizationList;
