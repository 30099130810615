import { Box, CircularProgress } from "@mui/material";
const Loader = () => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <CircularProgress color="warning" size="lg" value={40} />
    </Box>
  );
};

export default Loader;
