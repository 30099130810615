import { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import UserIsAuthenticated from "hooks/userIsAuthenticated";
import LoginPage from "scenes/loginPage";
import Dashboard from "scenes/dashboard";
import RegisterPage from "scenes/register";
import ConfirmAccountPage from "scenes/confirmAccount";
import Layout from "components/Layout";
import OrganizationListPage from "scenes/organizations";
import OrganizationDetailsPage from "scenes/organizations/OrganizationDetails";
import MembersPage from "scenes/members";
import ActivateAccountPage from "scenes/activateAccount";
import ActivationSuccessPage from "scenes/activationSuccess";

const AppRoutes = (props) => {
  const [mbo, setMbo] = useState("");
  const [mboName, setMboName] = useState("");

  return (
    <Layout {...props} setMbo={setMbo} setMboName={setMboName}>
      <Routes>
        <Route
          path="dashboard"
          element={<Dashboard mainMbo={mbo} selectedMboName={mboName} />}
        />
        <Route
          path="organizations"
          element={
            <OrganizationListPage mainMbo={mbo} selectedMboName={mboName} />
          }
        />
        <Route
          path="organizations/:id"
          element={
            <OrganizationDetailsPage mainMbo={mbo} selectedMboName={mboName} />
          }
        />
        <Route
          path="members"
          element={<MembersPage mainMbo={mbo} selectedMboName={mboName} />}
        />
      </Routes>
    </Layout>
  );
};

const Router = () => {
  // get if the user is authenticated from the custom hook
  const authenticated = UserIsAuthenticated();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/confirm" element={<ConfirmAccountPage />} />
        <Route path="/signup" element={<RegisterPage />} />
        <Route path="/activate" element={<ActivateAccountPage />} />
        <Route path="/succeeded" element={<ActivationSuccessPage />} />
        <Route path="*" element={<LoginPage />} />

        {/** Authenticated Routes */}
        <Route
          path="app/*"
          element={authenticated ? <AppRoutes /> : <Navigate to="/" />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
