import { useState, useEffect } from "react";
import {
  Stack,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";

import { GET_MY_MBOS } from "graphql/queries/organizationQueries";
import { UPDATE_MBO } from "graphql/mutations/organizationMutations";
import Loader from "./Loader";

const EditOrganizationForm = ({ open, setOpen, organization }) => {
  const [name, setName] = useState(organization.name);
  const [email, setEmail] = useState(organization.email);
  const [phone, setPhone] = useState(organization.phone);
  const [address, setAddress] = useState(organization.address);
  const [website, setWebsite] = useState(organization.website);
  const [description, setDescription] = useState(organization.description);
  const [id, setId] = useState(organization.id);

  const [updateMbo] = useMutation(UPDATE_MBO, {
    refetchQueries: [{ query: GET_MY_MBOS }],
  });

  const handleClose = () => {
    setId(organization.id);
    setName(organization.name);
    setEmail(organization.email);
    setPhone(organization.phone);
    setAddress(organization.address);
    setWebsite(organization.website);
    setDescription(organization.description);
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    const { data, error } = await updateMbo({
      variables: { id, name, email, phone, address, website, description },
    });

    setId("");
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setWebsite("");
    setDescription("");
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent sx={{ padding: 5 }}>
          <Grid container>
            <form>
              <Grid item xs={20}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={20}>
                    <Typography variant="h3">Edit An Organization</Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={7}>
                    <TextField
                      type="text"
                      variant="outlined"
                      color="secondary"
                      label="Name of Organization"
                      required
                      size="small"
                      className="inputRounded"
                      sx={{
                        width: 355,
                        background: "#ffffff",
                        border: "1px solid #d9d9d9",
                        borderRadius: "12px",
                        marginTop: "10px",
                      }}
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      color="secondary"
                      label="Corporate Email of Organization"
                      size="small"
                      className="inputRounded"
                      sx={{
                        width: 355,
                        background: "#ffffff",
                        border: "1px solid #d9d9d9",
                        borderRadius: "12px",
                        marginTop: "15px",
                      }}
                      value={email || ""}
                      onChange={(event) => setEmail(event.target.value)}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      color="secondary"
                      label="Phone Number of Organization"
                      size="small"
                      className="inputRounded"
                      sx={{
                        width: 355,
                        background: "#ffffff",
                        border: "1px solid #d9d9d9",
                        borderRadius: "12px",
                        marginTop: "15px",
                      }}
                      value={phone || ""}
                      onChange={(event) => setPhone(event.target.value)}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      color="secondary"
                      label="Address"
                      size="small"
                      className="inputRounded"
                      sx={{
                        width: 355,
                        background: "#ffffff",
                        border: "1px solid #d9d9d9",
                        borderRadius: "12px",
                        marginTop: "15px",
                      }}
                      value={address || ""}
                      onChange={(event) => setAddress(event.target.value)}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      color="secondary"
                      label="Website"
                      size="small"
                      className="inputRounded"
                      sx={{
                        width: 355,
                        background: "#ffffff",
                        border: "1px solid #d9d9d9",
                        borderRadius: "12px",
                        marginTop: "15px",
                      }}
                      value={website || ""}
                      onChange={(event) => setWebsite(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12} align="right" style={{ padding: 0 }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        margin="dense"
                        multiline
                        required
                        rows="5"
                        variant="outlined"
                        label="Description"
                        id="mbo-description"
                        sx={{
                          width: 355,
                          background: "#ffffff",
                          border: "1px solid #d9d9d9",
                          borderRadius: "12px",
                          marginTop: "15px",
                        }}
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={handleClose} size="large" sx={ButtonStyle}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit} size="large" sx={ButtonStyle}>
                    Update
                  </Button>
                </DialogActions>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ButtonStyle = {
  color: "#ffffff",
  backgroundColor: "#CB9E00",
  margin: 1,
  padding: 1,
  borderRadius: 10,
};

export default EditOrganizationForm;
