import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { AUTH_TOKEN } from "constants";

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      navigate("/app/dashboard");
    }

    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <div style={Container}>
      <h3 style={WelcomeText}>Hi Username,</h3>
      <h1 style={WelcomeHeader}>Welcome to XCLUSV</h1>
      <div style={{ marginTop: "25vh", paddingLeft: "80px" }}>
        <p style={SplashText}>
          You are currently not in an organization. Create an organization in
          order to move forward
        </p>
      </div>
      <div style={{ marginTop: "15vh", paddingLeft: "80px" }}>
        <p style={SplashLink}>
          <Link to="/app/organizations">create an organization</Link>
        </p>
      </div>
    </div>
  );
};

const Container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "@media (minWidth:576px)": {
    flexDirection: "row",
  },
};

const WelcomeText = {
  position: "absolute",
  width: "287.19px",
  height: "40.86px",
  paddingLeft: "80px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "25px",
  lineHeight: "120%",
};

const WelcomeHeader = {
  position: "absolute",
  width: "901.65px",
  height: "112.6px",
  paddingLeft: "80px",
  paddingTop: "20px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "55px",
};

const SplashText = {
  position: "absolute",
  width: "760px",
  height: "43px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "19px",
};

const SplashLink = {
  position: "absolute",
  width: "219px",
  height: "16px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "13px",
  lineHeight: "16px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  letterSpacing: "0.08em",
  textDecorationLine: "underline",
  textTransform: "uppercase",
};

export default Dashboard;
