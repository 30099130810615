import { useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  Link,
  Grid,
  Checkbox,
  Paper,
  Stack,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { Sort } from "@mui/icons-material";
import { PDFDownloadLink } from "@react-pdf/renderer";

import Loader from "components/Loader";
import FileUploadComponent from "./FileUploadComponent";
import { columns } from "constants";
import { validateData } from "utils";
import PdfReport from "./reports/PdfReport";

const getDate = () => {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
};

const AddBulkMemberModal = ({
  open,
  handleOpenBulkModal,
  handleCloseBulkModal,
  mainMbo,
  fileData,
  setFileData,
  selectedMbo,
}) => {
  const [parsedData, setParsedData] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [validatedData, setValidatedData] = useState([]);
  const [currentDate, setCurrentDate] = useState(getDate());
  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };

  // validate the data in the parsed data array
  const validateParsedData = (event) => {
    parsedData.forEach((item, index) => {
      const result = validateData(item);
      const { error, value } = result;

      if (typeof error !== "undefined") {
        const row = index + 2;
        setErrorList((errorList) => [
          ...errorList,
          { row: row, description: error.message },
        ]);
      } else {
        setValidatedData((validatedData) => [...validatedData, value]);
      }
    });
  };

  // uploads the validated data to the api
  const uploadData = () => {
    console.log("upload data ");
  };

  return (
    <Modal
      aria-labelledby="invite-users-modal-title"
      aria-describedby="invite-users-modal-description"
      open={open}
      onClose={handleOpenBulkModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={ModalStyle}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Typography
                id="invite-users-modal-title"
                variant="h1"
                component="h1"
                sx={{ fontWeight: "700", fontSize: "50px" }}
              >
                Invite Users Bulk File Upload
              </Typography>
              <ul>
                <li>
                  <Typography
                    id="invite-users-modal-description"
                    sx={{
                      mt: 2,
                      fontWeight: "400",
                      fontSize: "18px",
                      color: "#000000",
                    }}
                  >
                    Invite multiple users to your organisation by dragging or
                    clicking the file upload section below.
                  </Typography>
                </li>
                <li>
                  <Typography
                    id="invite-users-modal-description"
                    sx={{
                      mt: 2,
                      fontWeight: "400",
                      fontSize: "18px",
                      color: "#000000",
                    }}
                  >
                    You can download a sample template of how your files should
                    be structured here:{" "}
                    <Link
                      href={
                        process.env.PUBLIC_URL + "/documents/sample_upload.csv"
                      }
                      style={UploadLink}
                      target="_blank"
                      rel="noreferrer"
                      download={"sample.csv"}
                    >
                      Download Sample CSV File
                    </Link>
                  </Typography>
                </li>
                <li>
                  <Typography
                    id="invite-users-modal-description"
                    sx={{
                      mt: 2,
                      fontWeight: "400",
                      fontSize: "18px",
                      color: "#000000",
                    }}
                  >
                    {" "}
                    Only the email fields are required*
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container>
            <br />
            <br />
            <Grid item xs={6}>
              <FileUploadComponent
                setFileData={setFileData}
                setParsedData={setParsedData}
                setValidatedData={setValidatedData}
                setErrorList={setErrorList}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  width: "90%",
                  height: "11rem",
                  display: "flex",
                  flexWrap: "wrap",
                  background: "#002147",
                  marginLeft: 40,
                  border: "2px #ffffff",
                  borderRadius: "20px",
                  paddingTop: 10,
                  paddingLeft: 10,
                }}
              >
                {parsedData && parsedData.length > 0 ? (
                  <p style={{ paddingLeft: 5, color: "#ffffff" }}>
                    1. Data Extraction from CSV Complete....
                  </p>
                ) : null}

                {validatedData && validatedData.length > 0 ? (
                  <Stack direction="row" spacing={2}>
                    <p style={{ paddingLeft: 5, color: "#ffffff" }}>
                      2. Number of valid records: {validatedData.length}
                    </p>
                    <Button
                      onClick={uploadData}
                      sx={ReportButton}
                      variant="contained"
                    >
                      Upload Valid Rows
                    </Button>
                  </Stack>
                ) : null}

                {errorList && errorList.length > 0 ? (
                  <Stack direction="row" spacing={3}>
                    <p style={{ color: "#ffffff" }}>
                      3. Number of bad records: {errorList.length}
                    </p>
                    <div>
                      <PDFDownloadLink
                        document={
                          <PdfReport
                            currentDate={currentDate}
                            errors={errorList}
                            mboName={selectedMbo}
                          />
                        }
                        fileName="errors.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <p style={{ color: "#ffffff" }}>
                              Loading document...
                            </p>
                          ) : (
                            <Button sx={ReportButton} variant="contained">
                              Download Errors
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                  </Stack>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12} padding={2}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  disabled={parsedData.length > 0 ? false : true}
                  onClick={validateParsedData}
                  sx={InviteUsers}
                >
                  Validate CSV Data
                </Button>

                <Button
                  sx={CancelButton}
                  onClick={handleCloseBulkModal}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "85vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ButtonDiv = {
  marginTop: "0vh",
  display: "flex",
  flexDirection: "row",
};
const CancelButton = {
  color: "#000000",
  background: "#EEEEEE",
  width: "20em",
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

const InviteUsers = {
  color: "#ffffff",
  background: "#002147",
  width: "20em",
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

const ReportButton = {
  color: "#ffffff",
  background: "#CB9E00",
  width: "15em",
  height: "3em",
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

const UploadLink = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "13px",
  lineHeight: "16px",
  letterSpacing: "0.08em",
  textDecorationLine: "underline",
  textTransform: "uppercase",
  color: "#CB9E00",
};

const UploadReport = {
  margin: "0.5em",
  width: "35em",
  height: "8em",
  background: "#f1fef5",
  border: "1px solid #95c9b4",
  borderRadius: "12px",
};

export default AddBulkMemberModal;
