export const SidebarItems = [
  {
    name: "Dashboard",
    route: "/app/dashboard",
  },
  {
    name: "Organizations",
    route: "/app/organizations",
  },
  {
    name: "Members",
    route: "/app/members",
  },
];
