import LoginForm from "./LoginForm";

const LoginPage = () => {
  return (
    <div style={AuthContainer}>
      <LoginForm />
    </div>
  );
};

const AuthContainer = {
  backgroundColor: "#07162b",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default LoginPage;
