import SignUpForm from "./SignUpForm";

const RegisterPage = () => {
  return (
    <div style={AuthContainer}>
      <SignUpForm />
    </div>
  );
};

const AuthContainer = {
  backgroundColor: "#07162b",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default RegisterPage;
