import React from "react";
import Header from "scenes/header";
import SideBar from "scenes/sidebar";

const Layout = (props) => {
  return (
    <>
      <Header />
      <SideBar setMbo={props.setMbo} setMboName={props.setMboName} />
      <div style={HeaderContainer}>{props.children}</div>
    </>
  );
};

const HeaderContainer = {
  background: "#F9F9F9",
  position: "absolute",
  top: "10vh",
  left: "25vw",
  width: "75%",
  height: "90vh",
};

export default Layout;
