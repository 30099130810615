import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik/dist";
import { Box, Button, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ACTIVATE_ACCOUNT } from "graphql/mutations/authMutations";

const initialValuesActivate = {
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
};

const activationSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  password: yup
    .string()
    .required("required")
    .min(8, "Password must be Minimum of 8 characters"),
  confirmPassword: yup
    .string()
    .required("required")
    .min(8, "Password must be Minimum of 8 characters")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ActivateForm = ({ email, hashNum }) => {
  const [activateAccount] = useMutation(ACTIVATE_ACCOUNT);
  const navigate = useNavigate();

  const handleFormSubmit = async (values, onSubmitProps) => {
    const { firstName, lastName, password } = values;

    try {
      const { data, error } = await activateAccount({
        variables: {
          email: email,
          hash: hashNum,
          password: password,
          first_name: firstName,
          last_name: lastName,
        },
      });

      if (data.completeUserAccount.success) {
        toast.success("Successfully activated your account");
        navigate("/succeeded", { state: { email: email, hashNum: hashNum } });
      }
    } catch (error) {
      toast.error("Account is already active.");
    }
  };
  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesActivate}
        validationSchema={activationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              marginLeft="auto"
              marginRight="auto"
              width={400}
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h2"
                sx={{ color: "#FFF", fontSize: "25px" }}
                padding={3}
              >
                Activate Your Account
              </Typography>

              <TextField
                placeholder="*Email"
                name="email"
                type={"email"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={email}
                error={Boolean(touched.email) && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                required
                disabled={true}
                variant="outlined"
                margin="normal"
                sx={{
                  background: "#ffffff",
                  width: { sm: 200, md: 300 },
                }}
              />

              <TextField
                placeholder="*First Name"
                name="firstName"
                type={"text"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                error={Boolean(touched.firstName) && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                required
                variant="outlined"
                margin="normal"
                sx={{
                  background: "#ffffff",
                  width: { sm: 200, md: 300 },
                }}
              />

              <TextField
                placeholder="*Last Name"
                name="lastName"
                type={"text"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                required
                variant="outlined"
                margin="normal"
                sx={{
                  background: "#ffffff",
                  width: { sm: 200, md: 300 },
                }}
              />

              <TextField
                placeholder="*Create A Password"
                name="password"
                type={"password"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                required
                variant="outlined"
                margin="normal"
                sx={{
                  background: "#ffffff",
                  width: { sm: 200, md: 300 },
                }}
              />

              <TextField
                placeholder="*Confirm Password"
                name="confirmPassword"
                type={"password"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                error={
                  Boolean(touched.confirmPassword) &&
                  Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                required
                variant="outlined"
                margin="normal"
                sx={{
                  background: "#ffffff",
                  width: { sm: 200, md: 300 },
                }}
              />

              <Button
                variant="outlined"
                type="submit"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#CB9E00",
                  color: "#020202",
                  "&:hover": { color: "#FFF" },
                  borderRadius: 12,
                  fontSize: "15px",
                  fontWeight: "bold",
                  m: 3,
                  width: { sm: 200, md: 300 },
                }}
              >
                Activate Account
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <ToastContainer />
    </>
  );
};

export default ActivateForm;
