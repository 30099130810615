import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ActivateForm from "./ActivateForm";
import { isEmail } from "utils";

const ActivateAccountPage = () => {
  const [queryParams, setQueryParams] = useSearchParams();
  const email = queryParams.get("email");
  const hashNum = queryParams.get("hash");
  const navigate = useNavigate();

  useEffect(() => {
    if (email === "" && !isEmail(email) && hashNum === "") {
      navigate("/signup");
    }
  }, []);

  return (
    <div style={AuthContainer}>
      <ActivateForm email={email} hashNum={hashNum} />
    </div>
  );
};

const AuthContainer = {
  backgroundColor: "#07162b",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default ActivateAccountPage;
