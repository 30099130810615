import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik/dist";
import {
  Container,
  Stack,
  Typography,
  TextField,
  Button,
  Link,
  Alert,
} from "@mui/material";
import * as yup from "yup";

import {
  createUserWithEmailAndPassword,
  auth,
  signInWithEmailAndPassword,
} from "../../firebase.config";
import { SIGNUP_USER } from "graphql/mutations/authMutations";
import { AUTH_TOKEN } from "constants";
import { SEND_EMAIL_VERIFICATION } from "graphql/mutations/authMutations";

const registrationSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup
    .string()
    .required("required")
    .min(8, "Password must be 8 characters or longer"),
  confirmPassword: yup
    .string()
    .required("required")
    .min(8, "Password must be 8 characters or longer")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const initialValuesRegister = {
  email: "",
  password: "",
  confirmPassword: "",
};

const SignUpForm = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [createUser] = useMutation(SIGNUP_USER);
  const [verifyEmail] = useMutation(SEND_EMAIL_VERIFICATION);

  const navigate = useNavigate();

  const handleFormSubmit = async (values, onSubmitProps) => {
    const { email, password } = values;

    try {
      // calls the graphql mutation to register the user
      const { error } = await createUser({
        variables: { email, password },
      });

      if (error) {
        setErrorMessage(error.message);
      } else {
        // authenticate user and get token
        const firebaseAuth = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        localStorage.setItem(AUTH_TOKEN, firebaseAuth.user.accessToken);
        const { data } = await verifyEmail();
        const { success } = data.sendEmailVerification;

        if (success) {
          // TODO: pass the created user to confirmation page
          navigate("/confirm", { state: { email: email } });
        } else {
          setErrorMessage("An error occured");
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesRegister}
      validationSchema={registrationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Container spacing={2} maxWidth="sm">
            <Stack spacing={2} paddingBottom={1} width="30vw">
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#FFF",
                  fontSize: "25px",
                }}
              >
                Create Your Account:
              </Typography>
              <TextField
                placeholder="Email"
                name="email"
                type={"email"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                error={Boolean(touched.email) && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                required
              />
              <TextField
                placeholder="Create A Password"
                name="password"
                type={"password"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                required
              />
              <TextField
                placeholder="Confirm Password"
                name="confirmPassword"
                type={"password"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                error={
                  Boolean(touched.confirmPassword) &&
                  Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                required
              />

              <Button
                onClick={handleSubmit}
                fullWidth
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: "#CB9E00",
                  color: "#020202",
                  "&:hover": { color: "#FFF" },
                  borderRadius: "2.5rem",
                }}
              >
                Register
              </Button>
            </Stack>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

            <Link href="/" underline="always">
              <Typography
                sx={{
                  textDecoration: "underline",
                  textAlign: "center",
                  color: "#FFF",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                Already have an account? Login here.
              </Typography>
            </Link>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default SignUpForm;
