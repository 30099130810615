import { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";

import EditOrganizationForm from "components/EditOrganisationForm";

const OrganizationItem = ({ organization }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const orgStr = organization.name.slice(0, 2).toUpperCase();
  return (
    <>
      <Card sx={{ width: "90%", marginTop: 2 }}>
        <CardHeader
          titleTypographyProps={{
            align: "left",
            variant: "h3",
            color: "#000000",
          }}
          avatar={
            <Avatar
              sx={{ bgcolor: "#CB9E00", width: 56, height: 56 }}
              aria-label="recipe"
            >
              {orgStr}
            </Avatar>
          }
          action={
            <Stack direction="column" spacing={2}>
              <Button
                size="small"
                sx={ButtonStyle}
                LinkComponent={Link}
                to={`./${organization.id}`}
              >
                VIEW ORGANIZATION
              </Button>
              <Button size="small" sx={ButtonStyle} onClick={handleClickOpen}>
                EDIT ORGANIZATION
              </Button>
            </Stack>
          }
          title={organization.name}
        />
      </Card>
      <EditOrganizationForm
        open={open}
        setOpen={setOpen}
        organization={organization}
      />
    </>
  );
};

const ItemContainer = {
  width: "40vw",
  height: "20vh",
  background: "#ffffff",
  borderRadius: "12px",
  margin: "40px",
  display: "flex",
};

const LogoDiv = {
  margin: "10px",
  padding: "10px",
};

const Logo = {
  width: "72px",
  height: "72px",
  borderRadius: "72px",
};

const Header = {
  width: "520px",
  height: "45px",
  fontWeight: "700",
  fontSize: "55px",
  color: "#000000",
  lineHeight: "80%",
  paddingLeft: "10px",
};

const ButtonDiv = {
  marginTop: "5px",
  marginLeft: "120px",
  display: "flex",
  flexDirection: "column",
};

const ButtonStyle = {
  color: "#ffffff",
  backgroundColor: "#000000",
  margin: 1,
  padding: 1,
  borderRadius: 10,
};

export default OrganizationItem;
