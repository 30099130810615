import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import Logo from "../../assets/xclusv_logo.png";
import { useQuery } from "@apollo/client";

import { SidebarItems } from "components/SidebarItems";
import { GET_MY_MBOS } from "graphql/queries/organizationQueries";
import Loader from "components/Loader";

const SideBar = ({ setMbo, setMboName }) => {
  const [mboId, setMboId] = useState("");

  const { loading, error, data } = useQuery(GET_MY_MBOS);

  if (loading) return <Loader />;

  const handleMboChange = (event, child) => {
    console.log(child.props.name);
    setMboId(event.target.value);
    setMbo(event.target.value);
    setMboName(child.props.name);
  };

  const { adminMBOs } = data;
  return (
    <div style={Container}>
      <div style={SidebarStyle}>
        <div style={LogoContainer}>
          <Box
            component="img"
            sx={{
              height: "10vh",
              width: "15vw",
              minHeight: "10vh",
              minWidth: "5vw",
            }}
            alt="Xclusv logo."
            src={Logo}
            padding="15px 10px"
          />
        </div>
        <nav style={Menu}>
          <ul style={MenuItemStyle}>
            {SidebarItems.map((item, index) => (
              <li key={index + "-menu-item"}>
                <Link to={item.route} style={MenuLink}>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div>
          <FormControl>
            <InputLabel
              id="select-global-mbo-label"
              style={{ color: "#ffffff" }}
              variant="outlined"
            >
              Select an Organisation
            </InputLabel>
            <Select
              labelId="select-global-mbo-label"
              id="select-global-mbo-list"
              value={mboId}
              sx={{
                ".MuiSelect-icon": {
                  color: "white",
                },
                ".MuiSelect-outlined": {
                  color: "white",
                },
              }}
              style={{
                width: "15em",
                height: "3em",
                marginLeft: "0.25em",
                background: "#21379C",
              }}
              onChange={handleMboChange}
              displayEmpty
            >
              {adminMBOs &&
                adminMBOs?.length > 0 &&
                adminMBOs.map((organization) => (
                  <MenuItem
                    key={organization.id}
                    value={organization.id}
                    name={organization.name}
                  >
                    {organization.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

const Container = {
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  top: "0",
  left: "0",
  "@media (minWidth:576px)": {
    flexDirection: "row",
  },
};

const SidebarStyle = {
  background: "#07162b",
  width: "25vw",
  textAlign: "center",
  height: "100vh",

  "@media (minWidth:576px)": {
    position: "relative",
    flex: 1,
  },
};

const Menu = {
  padding: "24px 0",
};

const MenuItemStyle = {
  padding: "0",
  listStyle: "none",
};

const MenuLink = {
  padding: "12px",
  textDecoration: "none",
  display: "block",
  color: "#ffffff",
  "&:hover": {
    background: "gray",
  },
};

const LogoContainer = {
  margin: "12px 15px",
};

export default SideBar;
