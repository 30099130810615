import { useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";

import { GET_MBO_GROUPS } from "graphql/queries/organizationQueries";
import { ADD_MBO_MEMBERS_TO_GROUP } from "graphql/mutations/membersMutations";
import Loader from "components/Loader";

const AddMemberGroupModal = ({
  open,
  handleOpenCreate,
  handleCloseCreate,
  mainMbo,
  members,
}) => {
  const [groupId, setGroupId] = useState("Select a Group");
  const [groups, setGroups] = useState([]);
  const { loading, error, data } = useQuery(GET_MBO_GROUPS, {
    variables: { mbo_id: mainMbo },
    skip: mainMbo === "",
  });
  const [addMembersToGroup] = useMutation(ADD_MBO_MEMBERS_TO_GROUP);

  if (error) {
    toast.error("An Error Occured, please try again");
  }

  const handleGroupChange = (event) => {
    setGroupId(event.target.value);
  };

  let displayMenu = <p>No Groups Avaialable</p>;
  if (data) {
    displayMenu = (
      <Select
        labelId="select-mbo-label"
        id="select-mbo-invite-user"
        label="Select a Group"
        value={groupId}
        onChange={handleGroupChange}
        style={{
          width: "15em",
          height: "2em",
          marginLeft: "0.25em",
        }}
      >
        <MenuItem value="Select a Group">
          <em> -- Select a Group -- </em>
        </MenuItem>
        {data.mboGroups ? (
          data.mboGroups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
              {group.name}
            </MenuItem>
          ))
        ) : (
          <p>No Groups Available</p>
        )}
      </Select>
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (groupId === "Select a Group") {
      toast.info("Please Select A Group");
    } else if (members.length === 0) {
      toast.info("Please Select A Member(s) to");
    } else {
      // calls the graphql mutation to add members to selected group
      const { data, error } = await addMembersToGroup({
        variables: { group_id: groupId, member_ids: members },
      });

      if (data.addMembersToMBOGroup.success) {
        toast.success("Successfully added member(s) to group");
      } else {
        toast.error("An error occurred, please try again later");
      }

      // reset the values
      setGroupId("Select a Group");
    }
  };

  const handleCloseModal = async (event) => {
    // reset the values
    setGroupId("Select a Group");
    handleCloseCreate();
  };

  return (
    <>
      <Modal
        aria-labelledby="add-member-group-modal-title"
        aria-describedby="add-member-group-modal-description"
        open={open}
        onClose={handleOpenCreate}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={ModalStyle}>
            <Typography
              id="create-member-group-modal-title"
              variant="h1"
              component="h1"
              sx={{ fontWeight: "700", fontSize: "55px" }}
            >
              Add Member(s) To A Group
            </Typography>
            <Typography
              id="create-member--group-modal-description"
              sx={{
                mt: 2,
                fontWeight: "400",
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Add the selected members from the previous screen to a group
            </Typography>
            <InputLabel id="select-mbo-single-user">Group</InputLabel>
            {displayMenu}
            <div style={ButtonDiv}>
              <Button sx={InviteUsers} onClick={handleSubmit}>
                Add To Group
              </Button>
              <Button sx={CancelButton} onClick={handleCloseModal}>
                Cancel
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer />
    </>
  );
};

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "60vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ButtonDiv = {
  marginTop: "5vh",
  display: "flex",
  flexDirection: "row",
};
const CancelButton = {
  color: "#000000",
  background: "#EEEEEE",
  width: "20em",
  margin: 1,
  padding: 1,
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

const InviteUsers = {
  color: "#000000",
  background: "#CB9E00",
  width: "20em",
  margin: 1,
  padding: 1,
  border: "1.00782px solid #D9D9D9",
  borderRadius: "17px",
};

export default AddMemberGroupModal;
