import { gql } from "@apollo/client";

// Ends A Membership in an MBO
const REMOVE_MBO_MEMBERS = gql`
  mutation removeMBOMembers($mbo_id: String!, $member_ids: [String!]) {
    removeMBOMembers(
      MBOMembersData: { mbo_id: $mbo_id, member_ids: $member_ids }
    ) {
      success
      message
    }
  }
`;

// Make a Member an Admin
const MAKE_MBO_MEMBER_ADMIN = gql`
  mutation addMBOAdmins($mbo_id: String!, $user_ids: [String!]) {
    addMBOAdmins(MBOAdminsData: { mbo_id: $mbo_id, user_ids: $user_ids }) {
      success
      message
    }
  }
`;

// Adds an mbo member to a group
const ADD_MBO_MEMBERS_TO_GROUP = gql`
  mutation addMembersToMBOGroup($group_id: String!, $member_ids: [String!]) {
    addMembersToMBOGroup(
      MBOGroupMembersData: { group_id: $group_id, member_ids: $member_ids }
    ) {
      success
      message
    }
  }
`;

// creates a group
const CREATE_MBO_GROUP = gql`
  # prettier-ignore
  mutation createMBOGroup(
    $mbo_id: String!,
    $name: String!,
    $member_ids: [String!]
  ) {
    createMBOGroup(
      MBOGroupData: { mbo_id: $mbo_id, name: $name, member_ids: $member_ids }
    ) {
      name
    }
  }
`;

// removes a members admin privileges
const REMOVE_ADMIN_PRIVILEGES = gql`
  mutation removeMBOAdmins($mbo_id: String!, $admin_ids: [String!]) {
    removeMBOAdmins(MBOAdminsData: { mbo_id: $mbo_id, admin_ids: $admin_ids }) {
      success
      message
    }
  }
`;

// updateMBOMemberRequests status, MBOMemberRequestStatus.denied,  enum MBOMemberRequestStatus { pending, approved, denied }
const DENY_MEMBERSHIP_REQUEST = gql`
  mutation updateMBOMemberRequests(
    $mbo_id: String!
    $request_ids: [String!]
    $status_id: String!
  ) {
    updateMBOMemberRequests(
      MBOMemberRequestsData: {
        mbo_id: $mbo_id
        request_ids: $request_ids
        status_id: $status_id
      }
    ) {
      success
      message
    }
  }
`;

//  MBOMemberRequestStatus.approved, updateMBOMemberRequests
const APPROVE_MEMBERSHIP_REQUEST = gql`
  # prettier-ignore
  mutation updateMBOMemberRequests(
    $mbo_id: String!,
    $request_ids: [String!],
    $status_id: String!
  ) {
    updateMBOMemberRequests(
      MBOMemberRequestsData: {
        mbo_id: $mbo_id,
        request_ids: $request_ids,
        status_id: $status_id
      }
    ) {
      id
      status_id
    }
  }
`;

const REVOKE_MEMBERSHIP_INVITES = gql`
  mutation removeMBOInvitations($mbo_id: String!, $invitation_ids: [String!]) {
    removeMBOInvitations(
      MBOInvitationsData: { mbo_id: $mbo_id, invitation_ids: $invitation_ids }
    ) {
      success
      message
    }
  }
`;

export {
  REMOVE_MBO_MEMBERS,
  MAKE_MBO_MEMBER_ADMIN,
  ADD_MBO_MEMBERS_TO_GROUP,
  CREATE_MBO_GROUP,
  REMOVE_ADMIN_PRIVILEGES,
  DENY_MEMBERSHIP_REQUEST,
  APPROVE_MEMBERSHIP_REQUEST,
  REVOKE_MEMBERSHIP_INVITES,
};
