import { useState, useEffect } from "react";
import {
  Button,
  Stack,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Link,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AddMemberModal from "components/AddMemberModal";
import AddBulkMemberModal from "components/AddBulkMemberModal";
import AddGroupModal from "components/AddGroupModal";
import AddMemberGroupModal from "components/AddMemberGroupModal";
import {
  GET_MBO_MEMBERS,
  GET_MBO_ADMINS,
  GET_MBO_MEMBER_REQUESTS,
  GET_MBO_MEMBER_INVITATIONS,
} from "graphql/queries/organizationQueries";
import {
  REMOVE_MBO_MEMBERS,
  MAKE_MBO_MEMBER_ADMIN,
  ADD_MBO_MEMBERS_TO_GROUP,
  CREATE_MBO_GROUP,
  REMOVE_ADMIN_PRIVILEGES,
  DENY_MEMBERSHIP_REQUEST,
  APPROVE_MEMBERSHIP_REQUEST,
  REVOKE_MEMBERSHIP_INVITES,
} from "graphql/mutations/membersMutations";
import Loader from "components/Loader";
import { MEMBER_OPTIONS, MBO_MEMBER_REQUEST_STATUS } from "constants";

const MembersPage = ({ mainMbo, selectedMboName }) => {
  const [openInvite, setOpenInvite] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [openAddToGroup, setOpenAddToGroup] = useState(false);

  const [fetchMembers] = useLazyQuery(GET_MBO_MEMBERS);
  const [fetchAdmins] = useLazyQuery(GET_MBO_ADMINS);
  const [fetchRequests] = useLazyQuery(GET_MBO_MEMBER_REQUESTS);
  const [fetchInvitations] = useLazyQuery(GET_MBO_MEMBER_INVITATIONS);

  // membership button options mutations
  const [removeMboMembers] = useMutation(REMOVE_MBO_MEMBERS);
  const [makeMemberAdmin] = useMutation(MAKE_MBO_MEMBER_ADMIN);
  const [addToMboGroup] = useMutation(ADD_MBO_MEMBERS_TO_GROUP);
  const [createMboGroup] = useMutation(CREATE_MBO_GROUP);
  const [removeAdminPrivileges] = useMutation(REMOVE_ADMIN_PRIVILEGES);
  const [denyMembershipRequest] = useMutation(DENY_MEMBERSHIP_REQUEST);
  const [approveMembershipRequest] = useMutation(APPROVE_MEMBERSHIP_REQUEST);
  const [revokeMembershipInvite] = useMutation(REVOKE_MEMBERSHIP_INVITES);

  const [mboId, setMboId] = useState("");
  const [action, setAction] = useState("");
  const [members, setMembers] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // buttons shown based on selections
  const [isAllMembers, setIsAllMembers] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMemberRequest, setIsMemberRequest] = useState(false);
  const [isPendingInvite, setIsPendingInvite] = useState(false);

  const [fileData, setFileData] = useState([]);

  const handleInviteModal = () => setOpenInvite(true);
  const handleCloseInviteModal = () => setOpenInvite(false);

  const handleOpenBulkModal = () => setOpenBulk(true);
  const handleCloseBulkModal = () => {
    setOpenBulk(false);
    setFileData([]);
  };

  const handleOpenGroupModal = () => setOpenGroup(true);
  const handleCloseGroupModal = () => setOpenGroup(false);

  const handleOpenAddGroupModal = () => setOpenAddToGroup(true);
  const handleCloseAddGroupModal = () => setOpenAddToGroup(false);

  useEffect(() => {
    if (mainMbo !== "") {
      setMboId(mainMbo);
    } else {
      setMboId("");
    }
  }, [mboId, mainMbo]);

  const handleMboChange = async (event) => {
    setAction(event.target.value);

    if (event.target.value === "") {
      setMembers([]);
      setIsAllMembers(false);
      setIsAdmin(false);
      setIsMemberRequest(false);
      setIsPendingInvite(false);
    }

    if (event.target.value === MEMBER_OPTIONS[0]) {
      const { data } = await fetchMembers({
        variables: { mbo_id: mainMbo },
      });

      if (data) {
        setMembers(data.mboMembers);
        setIsAllMembers(true);
        setIsAdmin(false);
        setIsMemberRequest(false);
        setIsPendingInvite(false);
      }
    } else if (event.target.value === MEMBER_OPTIONS[1]) {
      const { data } = await fetchAdmins({
        variables: { mbo_id: mainMbo },
      });

      if (data) {
        setMembers(data.mboAdmins);
        setIsAllMembers(false);
        setIsAdmin(true);
        setIsMemberRequest(false);
        setIsPendingInvite(false);
      }
    } else if (event.target.value === MEMBER_OPTIONS[2]) {
      const { data } = await fetchRequests({
        variables: { mbo_id: mainMbo },
      });

      if (data) {
        setMembers(data.mboMemberRequests);
        setIsAllMembers(false);
        setIsAdmin(false);
        setIsMemberRequest(true);
        setIsPendingInvite(false);
      }
    } else if (event.target.value === MEMBER_OPTIONS[3]) {
      const { loading, data } = await fetchInvitations({
        variables: { mbo_id: mainMbo },
      });

      if (data) {
        setMembers(data.mboMemberInvitations);
        setIsAllMembers(false);
        setIsAdmin(false);
        setIsMemberRequest(false);
        setIsPendingInvite(true);
      }
    }
  };

  const handleSelectAll = (event) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(members.map((member) => member.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (event) => {
    const { id, checked } = event.target;
    setIsCheck([...isCheck, id]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // Event handlers for the membership display buttons
  const handleEndMembership = async () => {
    if (isCheck.length === 0) {
      toast.info("Please Select A Member(s)");
    } else {
      const { data, error } = await removeMboMembers({
        variables: { mbo_id: mainMbo, user_ids: isCheck },
      });

      if (error) {
        toast.error("An error occurred, please try again later.");
      }

      if (data) {
        toast.success("Successfully ended membership(s)");
      }
    }
  };

  const handleMakeAdmin = async () => {
    if (isCheck.length === 0) {
      toast.info("Please Select A Member(s)");
    } else {
      const { data, error } = await makeMemberAdmin({
        variables: { mbo_id: mainMbo, member_ids: isCheck },
      });

      if (error) {
        toast.error("An error occurred, please try again later.");
      }

      if (data) {
        toast.success("Successfully made member(s) admin(s).");
      }
    }
  };

  const handleRemoveAdmin = async () => {
    if (isCheck.length === 0) {
      toast.info("Please Select A Member(s)");
    } else {
      const { data, error } = await removeAdminPrivileges({
        variables: { mbo_id: mainMbo, admin_ids: isCheck },
      });

      if (error) {
        toast.error("An error occurred, please try again later.");
      }

      if (data) {
        toast.success("Successfully removed member(s) as admin(s).");
      }
    }
  };

  const handleDenyRequest = async () => {
    if (isCheck.length === 0) {
      toast.info("Please Select A Member(s)");
    } else {
      const { data, error } = await denyMembershipRequest({
        variables: {
          mbo_id: mainMbo,
          request_ids: isCheck,
          status_id: MBO_MEMBER_REQUEST_STATUS["DENIED"],
        },
      });

      if (error) {
        toast.error("An error occurred, please try again later.");
      }

      if (data) {
        toast.success("Successfully denied membership request.");
      }
    }
  };

  const handleApproveRequest = async () => {
    if (isCheck.length === 0) {
      toast.info("Please Select A Member(s)");
    } else {
      const { data, error } = await approveMembershipRequest({
        variables: {
          mbo_id: mainMbo,
          request_ids: isCheck,
          status_id: MBO_MEMBER_REQUEST_STATUS["APPROVED"],
        },
      });

      if (error) {
        toast.error("An error occurred, please try again later.");
      }

      if (data) {
        toast.success("Successfully approved membership request.");
      }
    }
  };

  const handleRevokeInvitation = async () => {
    if (isCheck.length === 0) {
      toast.info("Please Select A Member(s)");
    } else {
      const { data, error } = await revokeMembershipInvite({
        variables: { mbo_id: mainMbo, invitation_ids: isCheck },
      });

      if (error) {
        toast.error("An error occurred, please try again later.");
      }

      if (data) {
        toast.success("Successfully revoked invitation.");
      }
    }
  };

  let selectedMbo;

  if (mainMbo === "") {
    selectedMbo = (
      <p style={MembershipMessage}>
        Please select an organisation from the list on the left to handle
        memberships.
      </p>
    );
  } else {
    selectedMbo = (
      <>
        <Select
          labelId="select-member-label"
          id="select-member"
          label="Select an Option"
          style={{
            width: "15em",
            height: "2em",
            marginLeft: "0.25em",
          }}
          value={action}
          displayEmpty
          onChange={handleMboChange}
        >
          <MenuItem value="">
            <em> Select an Option </em>
          </MenuItem>
          {MEMBER_OPTIONS.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <div style={SelectMboContainer}>
          {isAllMembers && (
            <>
              <Button
                style={SelectMboLightButton}
                onClick={handleEndMembership}
              >
                End Membership
              </Button>
              <div style={Divider} />
              <Button style={SelectMboLightButton} onClick={handleMakeAdmin}>
                Make Member Admin
              </Button>
              <div style={Divider} />
              <Button
                style={SelectMboLightButton}
                onClick={handleOpenAddGroupModal}
              >
                Add To Group
              </Button>
              <div style={Divider} />
              <Button
                style={SelectMboDarkButton}
                onClick={handleOpenGroupModal}
              >
                Create New Group
              </Button>
            </>
          )}

          {isAdmin && (
            <Button style={SelectMboDarkButton} onClick={handleRemoveAdmin}>
              Remove Admin Privileges
            </Button>
          )}

          {isMemberRequest && (
            <>
              <Button style={SelectMboLightButton} onClick={handleDenyRequest}>
                Deny Request
              </Button>
              <div style={Divider} />
              <Button
                style={SelectMboDarkButton}
                onClick={handleApproveRequest}
              >
                Approve Request
              </Button>
            </>
          )}

          {isPendingInvite && (
            <Button
              style={SelectMboLightButton}
              onClick={handleRevokeInvitation}
            >
              Revoke Invitation
            </Button>
          )}
        </div>
      </>
    );
  }

  const ItemLabel = ({ id, email, firstName, lastName }) => {
    return (
      <div style={{ display: "flex" }} key={id}>
        <div
          style={{
            flex: "1",
            width: "29vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <AccountCircle sx={{ color: "#CB9E00" }} />
            <div style={{ paddingLeft: "0.5vw" }}></div>
            {firstName && lastName ? (
              <>
                <Typography sx={{ color: "#CB9E00" }}>{firstName}</Typography>
                <div style={{ paddingLeft: "0.3vw" }}></div>
                <Typography sx={{ color: "#CB9E00" }}>{lastName}</Typography>
              </>
            ) : (
              <Typography sx={{ color: "#CB9E00" }}>{email}</Typography>
            )}
          </div>
        </div>
        <div style={{ width: "40vw" }}>
          {isAllMembers && (
            <>
              <Link
                component="button"
                variant="body1"
                color="#CB9E00"
                onClick={(id) => {
                  console.info(id);
                }}
                sx={{ color: "#CB9E00", marginLeft: "2vw" }}
              >
                End Membership
              </Link>

              <Link
                component="button"
                variant="body1"
                color="#CB9E00"
                onClick={() => {
                  console.info("I'm a button.");
                }}
                sx={{ color: "#CB9E00", marginLeft: "1vw" }}
              >
                Make Member Admin
              </Link>

              <Link
                component="button"
                variant="body1"
                color="#CB9E00"
                onClick={() => {
                  console.info("I'm a button.");
                }}
                sx={{ color: "#CB9E00", marginLeft: "1vw" }}
              >
                Add To Group
              </Link>

              <Link
                component="button"
                variant="body1"
                color="#CB9E00"
                onClick={() => {
                  console.info("I'm a button.");
                }}
                sx={{ color: "#CB9E00", marginLeft: "1vw" }}
              >
                Create New Group
              </Link>
            </>
          )}

          {isAdmin && (
            <>
              <Link
                component="button"
                variant="body1"
                color="#CB9E00"
                onClick={() => {
                  console.info("I'm a button.");
                }}
                sx={{ color: "#CB9E00", marginLeft: "26vw" }}
              >
                Remove Admin Privileges
              </Link>
            </>
          )}

          {isMemberRequest && (
            <>
              <Link
                component="button"
                variant="body1"
                color="#CB9E00"
                onClick={() => {
                  console.info("I'm a button.");
                }}
                sx={{ color: "#CB9E00", marginLeft: "20vw" }}
              >
                Deny Request
              </Link>

              <Link
                component="button"
                variant="body1"
                color="#CB9E00"
                onClick={() => {
                  console.info("I'm a button.");
                }}
                sx={{ color: "#CB9E00", marginLeft: "30vw" }}
              >
                Approve Request
              </Link>
            </>
          )}

          {isPendingInvite && (
            <>
              <Link
                component="button"
                variant="body1"
                color="#CB9E00"
                onClick={() => {
                  console.info("I'm a button.");
                }}
                sx={{ color: "#CB9E00", marginLeft: "30vw" }}
              >
                Revoke Invitation
              </Link>
            </>
          )}
        </div>
      </div>
    );
  };

  let itemsList = members.map((member) => {
    return (
      <>
        <FormControlLabel
          key={member.id}
          control={
            <Checkbox
              key={member.id}
              type="checkbox"
              name={member.user.email}
              id={member.id}
              onChange={handleClick}
              checked={isCheck.includes(member.id)}
            />
          }
          label={
            <ItemLabel
              key={member.id}
              id={member.id}
              email={member.user.email}
              firstName={member.user.first_name}
              lastName={member.user.last_name}
            />
          }
        />
      </>
    );
  });

  return (
    <>
      <Stack
        direction="row"
        spacing={10}
        sx={{ marginTop: "30px", marginLeft: "15px" }}
      >
        <h1 style={MemberHeading}>Members</h1>
        <div style={ButtonContainer}>
          <Button style={InviteButton} onClick={handleInviteModal}>
            Invite User
          </Button>
          <Button style={BulkButton} onClick={handleOpenBulkModal}>
            Bulk Invite
          </Button>
        </div>
      </Stack>
      <AddMemberModal
        open={openInvite}
        handleInviteModal={handleInviteModal}
        handleCloseInviteModal={handleCloseInviteModal}
        mainMbo={mainMbo}
      />
      <AddBulkMemberModal
        open={openBulk}
        handleOpenBulkModal={handleOpenBulkModal}
        handleCloseBulkModal={handleCloseBulkModal}
        mainMbo={mainMbo}
        fileData={fileData}
        setFileData={setFileData}
        selectedMbo={selectedMboName}
      />
      <AddGroupModal
        open={openGroup}
        handleOpenCreate={handleOpenGroupModal}
        handleCloseCreate={handleCloseGroupModal}
        mainMbo={mainMbo}
        members={isCheck}
      />
      <AddMemberGroupModal
        open={openAddToGroup}
        handleOpenCreate={handleOpenAddGroupModal}
        handleCloseCreate={handleCloseAddGroupModal}
        mainMbo={mainMbo}
        members={isCheck}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "80%",
          marginLeft: "2vw",
        }}
      >
        {selectedMbo}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "80%",
          marginLeft: "2vw",
        }}
      >
        {members.length > 0 ? (
          <div style={{ marginTop: "2vh" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                  />
                }
                label="SELECT ALL"
                sx={{ color: "#CB9E00", fontWeight: "bold" }}
              />

              {itemsList}
            </FormGroup>
          </div>
        ) : (
          <>
            <br />
            <br />
            <p style={Message}>There are no options for this selection.</p>
          </>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

const HeaderContainer = {
  margin: "2em",
  display: "flex",
  flexDirection: "row",
  padding: "5px",
};

const MemberHeading = {
  margin: "0.25em",
  width: "65%",
  lineHeight: "100%",
  fontSize: "55px",
  fontWeight: "700",
};

const ButtonContainer = {
  width: "35%",
  display: "flex",
  flexDirection: "row",
  margin: "1.5em",
  justifyContent: "space-evenly",
};

const InviteButton = {
  color: "#020202",
  background: "#BCBCBC",
  borderRadius: "12px",
  height: "3em",
  width: "12em",
  padding: "10px",
};

const BulkButton = {
  color: "#020202",
  background: "#CB9E00",
  borderRadius: "12px",
  height: "3em",
  width: "12em",
  padding: "10px",
};

const MembershipMessage = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "19px",
};

const Message = {
  margin: "0.25em",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "19px",
};

const SelectMboContainer = {
  display: "flex",
  flexDirection: "row",
  width: "85vw",
  height: "7vh",
  marginTop: "0.5vh",
  marginLeft: "2vw",
  padding: "2px",
};

const SelectMboLightButton = {
  color: "#020202",
  background: "#BCBCBC",
  height: "2em",
  width: "18em",
  padding: "10px",
  fontSize: "12px",
  marginTop: "1vh",
};

const SelectMboDarkButton = {
  color: "#020202",
  background: "#CB9E00",
  height: "2em",
  width: "18em",
  padding: "1px",
  fontSize: "12px",
  marginTop: "1vh",
};

const Divider = {
  width: "10px",
  height: "auto",
  display: "inline-block",
};

export default MembersPage;
