import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccountCircle, CircleNotifications } from "@mui/icons-material";
import { auth, signOut } from "firebase.config";

import { AUTH_TOKEN } from "constants";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    signOut(auth);
    localStorage.removeItem(AUTH_TOKEN);
    navigate("/");
  };

  useEffect(() => {
    let token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      navigate("/app/dashboard");
    }

    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <div style={HeaderContainer}>
      <div style={Dropdown}>
        <CircleNotifications
          sx={{
            width: "26px",
            height: "26px",
            color: "#CB9E00",
          }}
        />
        <IconButton
          aria-label="current user account"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          sx={{
            width: "40px",
            height: "40px",
            color: "#CB9E00",
          }}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

const HeaderContainer = {
  background: "#EEEEEE",
  position: "absolute",
  overflow: "hidden",
  top: 0,
  left: "20vw",
  width: "80%",
  height: "10vh",
};

const Dropdown = {
  float: "right",
  overflow: "hidden",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "5px",
};

export default Header;
