import { useState } from "react";
import { Button, Alert, Stack } from "@mui/material";
import { Facebook, Twitter } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";

import { GET_MBO, GET_MY_MBOS } from "graphql/queries/organizationQueries";
import { DELETE_MBO } from "graphql/mutations/organizationMutations";
import Loader from "components/Loader";

const OrganizationDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_MBO, { variables: { id } });
  const [deleteMbo] = useMutation(DELETE_MBO, {
    variables: { id: id },
    onCompleted: () => navigate("/app/organizations"),
    refetchQueries: [{ query: GET_MY_MBOS }],
  });

  if (loading) return <Loader />;

  const handleDelete = async () => {
    const { data, error } = await deleteMbo({
      variables: { id },
    });
  };

  return (
    <div style={Container}>
      <div style={BlueContainer}>
        <div style={BlueRectangle}>
          <h1 style={MboHeader}>{data.mbo.name}</h1>
          <p style={MboDescription}>{data.mbo.description}</p>
          <div style={AddressContainer}>
            <h4 style={AddressHeader}>ADDRESS</h4>
            <p style={Address}>{data.mbo.address}</p>
          </div>
        </div>
      </div>
      <div style={DetailSection}>
        <div style={ButtonDiv}>
          <Button
            sx={ButtonStyle}
            LinkComponent={Link}
            to={`/app/organizations`}
          >
            Go Back
          </Button>
        </div>
        <div style={ButtonDiv}>
          <Button sx={ButtonStyle} onClick={handleDelete}>
            Delete Organization
          </Button>
        </div>

        <h3 style={ContactHeader}>Contact Us</h3>
        <h4 style={ContactInfoHeader}>Website</h4>
        <p style={ContactInfo}>{data.mbo.website}</p>
        <br />
        <h4 style={ContactInfoHeader}>Email/Contact</h4>
        <p style={ContactInfo}>{data.mbo.email}</p>
        <p style={ContactInfo}>{data.mbo.phone_number}</p>

        <br />
      </div>
    </div>
  );
};

const Container = {
  display: "flex",
  width: "100%",
  height: "100%",
};

const BlueContainer = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
};

const BlueRectangle = {
  margin: "5vh",
  width: "100%",
  height: "10%",
  backgroundColor: "#b9d5ff",
  borderRadius: "12px",
};

const DetailSection = {
  margin: "5vh",
  width: "80%",
  height: "40%",
};

const MboHeader = {
  marginTop: "10vh",
  width: "100%",
  height: "5%",
  lineHeight: "100%",
  fontSize: "30px",
  fontWeight: "700",
  textTransform: "capitalize",
};

const MboDescription = {
  marginTop: "15vh",
  width: "90%",
  height: "15%",
  lineHeight: "120%",
  fontSize: "17px",
  fontWeight: "400",
  color: "#202223",
};

const MapContainer = {
  marginTop: "10vh",
  padding: "5px",
  width: "90%",
  height: "50%",
  backgroundColor: "#bbb",
};

const AddressContainer = {
  marginTop: "10vh",
  width: "100%",
  height: "20vh",
  background: "#07162b",
  borderRadius: "12px",
};

const AddressHeader = {
  marginLeft: "1vw",
  letterSpacing: "0.215em",
  lineHeight: "120%",
  color: "#ffffff",
};

const Address = {
  marginLeft: "0vh",
  fontWeight: "400",
  fontSize: "17px",
  lineHeight: "120%",
  padding: "1em",
  color: "#ffffff",
};

const ButtonDiv = {
  marginTop: "5px",
  marginLeft: "120px",
  display: "flex",
  flexDirection: "column",
};

const ButtonStyle = {
  color: "#1B1B1B",
  background: "#EEEEEE",
  width: "20em",
  margin: 1,
  padding: 1,
  border: "1.00782px solid #D9D9D9",
};

const ContactHeader = {
  marginTop: "5px",
  marginLeft: "100px",
  display: "flex",
  flexDirection: "column",
  fontWeight: "700",
  fontSize: "33px",
  lineHeight: "120%",
  color: "#000000",
};
const ContactInfoHeader = {
  marginTop: "5px",
  marginLeft: "100px",
  display: "flex",
  flexDirection: "column",
};
const ContactInfo = {
  marginLeft: "100px",
  lineHeight: "80%",
};

const ContactHours = {
  marginTop: "5px",
  marginLeft: "100px",
  display: "flex",
  flexDirection: "column",
};

const SocialMediaLinks = {
  marginTop: "5px",
  marginLeft: "100px",
  display: "flex",
  flexDirection: "row",
};

export default OrganizationDetailsPage;
