import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  styled,
  Stack,
  Typography,
  IconButton,
  Checkbox,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { Sort, CloudUpload } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ImageConfig } from "utils";
import { allowedTypes, columns } from "constants";

// custom sytels for the box component
const CustomBox = styled(Box)({
  "&.MuiBox-root": {
    backgroundColor: "#fff",
    borderRadius: "2rem",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "1rem",
  },
  "&.MuiBox-root:hover, &.MuiBox-root.dragover": {
    opacity: 0.6,
  },
});

const FileUploadComponent = ({
  setFileData,
  setParsedData,
  setValidatedData,
  setErrorList,
}) => {
  const [acceptedFile, setAcceptedFile] = useState([]);

  const [parsing, setParsing] = useState(false);
  const wrapperRef = useRef(null);

  // file drop handler
  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFile = Object.values(acceptedFiles).map((file) => file);

      if (!allowedTypes.includes(newFile[0]?.type)) {
        return toast.error("Only csv files allowed");
      }

      if (acceptedFiles.length > 1)
        return toast.error("Only a single file allowed");

      if (acceptedFile.length >= 1)
        return toast.error("Only a single file allowed");

      if (acceptedFile.length >= 1)
        return toast.error("Only a single file allowed");

      setAcceptedFile(newFile);
      setParsing(true);

      Papa.parse(newFile[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setParsedData(results.data);
        },
      });

      setParsing(false);
    },
    [acceptedFile]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  //Calculate Size in KiloByte and MegaByte
  const calcSize = (size) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  //remove single image
  const fileSingleRemove = () => {
    setFileData([]);
    setAcceptedFile([]);
    setParsedData([]);
    setValidatedData([]);
    setErrorList([]);
  };

  return (
    <>
      <CustomBox>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "relative",
            width: "100%",
            height: "10rem",
            border: "2px dashed #002147",
            borderRadius: "20px",
          }}
          ref={wrapperRef}
          onDrop={onDrop}
          {...getRootProps()}
          accept="text/csv"
        >
          <Stack justifyContent="center" sx={{ p: 1, textAlign: "center" }}>
            <Typography sx={{ color: "#002147" }}>
              Drag a file or Click to browse file to upload
            </Typography>
            <div>
              <CloudUpload
                sx={{ width: "10rem", color: "#CB9E00" }}
                alt="file upload"
              />
            </div>
            <Typography variant="body1" component="span">
              <strong>Supported Files</strong>
            </Typography>
            <Typography variant="body2" component="span">
              CSV
            </Typography>
          </Stack>
          <input
            type="file"
            name="file"
            accept=".csv"
            style={{
              opacity: 0,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            {...getInputProps()}
          />
        </Box>
      </CustomBox>

      {/* file details preview */}
      {acceptedFile.length > 0 ? (
        <Stack spacing={2} sx={{ my: 2 }}>
          {acceptedFile.map((item, index) => {
            const imageType = item.type.split("/")[1];
            return (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  backgroundColor: "#f5f8ff",
                  borderRadius: 1.5,
                  p: 0.5,
                }}
              >
                <Box display="flex">
                  <img
                    src={ImageConfig[`${imageType}`] || ImageConfig["default"]}
                    alt="upload"
                    style={{
                      height: "3.5rem",
                      objectFit: "contain",
                    }}
                  />
                  <Box sx={{ ml: 1 }}>
                    <Typography>{item.name}</Typography>
                    <Typography variant="body2">
                      {calcSize(item.size)}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  onClick={fileSingleRemove}
                  sx={{
                    color: "#df2c0e",
                    position: "absolute",
                    right: "1rem",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
      ) : null}
    </>
  );
};

export default FileUploadComponent;
