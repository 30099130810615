import Joi from "joi";

import defaultImage from "../assets/default.png";
import csv from "../assets/csv.png";

export const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const ImageConfig = {
  csv: csv,
  default: defaultImage,
};

const userSchema = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required(),
  first_name: Joi.string()
    .empty("")
    .pattern(new RegExp("^[a-zA-Z]*$"), "Only Letters"),
  last_name: Joi.string()
    .empty("")
    .pattern(new RegExp("^[a-zA-Z]*$"), "Only Letters"),
  group_1: Joi.string().empty(""),
  group_2: Joi.string().empty(""),
  group_3: Joi.string().empty(""),
});

export const validateData = (data) => {
  let result = userSchema.validate(data);
  return result;
};
